import { stageRoutes } from 'system/Routing/stageRoutes.lazy';
import { baseConfig } from './merchants/identance';
import { App } from './App';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${(window as any).appConfig.cdn}/`;

(async () => {
  await App(baseConfig, stageRoutes);
})();
